@import "commonStyles/commonStyles.scss";
.calendarComponent {
  margin-top: 24px;
}
.calendarRow {
  display: flex;
  justify-content: center;
}
.calendarSubtitlesWrapper {
  font-size: 18px;
  font-family: "Open Sans";
  text-decoration: none;
  font-weight: 700;
  color: $white-text;
  padding: 12px 12px;
  display: block;
  margin-top: 60px;
}
