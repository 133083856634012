@import "commonStyles/commonStyles.scss";
.gymDetailsContent {
  background-color: $white-background;
  margin-top: -8px;
  padding: $commonPadding;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 120px;
  .gymDetailsInnerWrapper {
    max-width: 1920px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .gymDetailsLeftSide {
    width: 70%;
    text-align: left;
    margin-bottom: 80px;
    .gymDetailsContentTitle {
      color: $black-text;
      text-align: center;
      font-family: "Black Ops One";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: start;
      margin-bottom: 24px;
    }
    .gymDetailsContentDescription {
      color: $black-text;
      text-align: justify;
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 24px;
      margin-bottom: 40px;
    }
    .gymDetailsContentDescriptionsSection {
      display: flex;
    }
  }
  .gymDetailsDumbellImage {
    margin-left: 32px;
    max-width: 25%;
  }
}

@media (max-width: $xxl) {
  .gymDetailsContent {
    padding: 0 100px;
    .gymDetailsLeftSide {
      .gymDetailsContentTitle {
        font-size: 40px;
      }
    }
  }
}
@media (max-width: $xl) {
  .gymDetailsContent {
    padding: 0 75px;
    .gymDetailsLeftSide {
      width: 100%;
    }
  }
  .gymDetailsDumbellImage {
    display: none;
  }
}
@media (max-width: $lg) {
  .gymDetailsContent {
    padding: 0 50px;
    .gymDetailsLeftSide {
      .gymDetailsContentDescriptionsSection {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
@media (max-width: $md) {
  .gymDetailsContent {
    padding: 0 24px;
    .gymDetailsLeftSide {
      .gymDetailsContentTitle {
        font-size: 32px;
      }
    }
  }
}
