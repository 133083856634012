@import "commonStyles/commonStyles.scss";
.localizationSectionWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 120px;
  .localizationSectionTitle {
    color: $white-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    margin-bottom: 24px;
  }
}
.localizationSectionAddress {
  color: $white-text;
  text-align: center;
  font-family: "Black Ops One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 24px;
}

.localizationSectionLocalizer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -50px;
  top: -150px;
  height: 100px;
}
@media (max-width: $xxl) {
  .localizationSectionWrapper {
    .localizationSectionTitle {
      font-size: 40px;
    }
  }
}
@media (max-width: $md) {
  .localizationSectionWrapper {
    .localizationSectionTitle {
      font-size: 32px;
    }
  }
  .localizationSectionAddress {
    font-size: 20px;
  }
  .localizationSectionLocalizer {
    height: 50px;
    left: -25px;
    top: -100px;
  }
}
